<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>
<script>
export default {
  name: "app",
  components: {},
  data() {
    return {
     
    };
  },
  mounted() {
     
  },
 
  methods: {
   
  
  },
};
</script>

<style>
/* 引入字体的样式 */
@import "../src/assets/css/font-family.css";
/* 引入全局css样式 */
@import "../src/assets/css/global.css";
</style>
