export const lang= {
    TREE:"Tenant",
    LANGUAGES:"多語言",
    SYSTEM_NAME:"xxxLuggage",
    COMPANY_NAME:"xxxxx",
    COPYRIGHT:" © {year} {company_name}公司, 版權所有。",
    INPUT:{
        placeholder:"Fill",
        placeholder_select:"選擇",
        datefomat:"年/月/日"
    },
    STATUS:{        
        LOST:"遺失",
        FOUND:"拾取",
        RETURNED:"已歸還",
        PENDING_RETURN:"待歸還",            
        PENDING:"待接收",
        ACCEPTED:"已接收",
        FINISHED:"已處理",
        UNFINISHED:"未處理",
        DETAIL:"詳情",
        SELF_PICKUP:"自取",
        POST:"郵寄",
        
    },
    BUTTON:{
        BUT_ADD: "添加",
        BUT_ADD_ICON:"添加圖標",
        BUT_EDIT:"編輯",
        BUT_FOUND:"拾取",
        BUT_DELETED:"刪除",
        BUT_TRANS:"轉移",
        BUT_RETURN:"歸還",
        BUT_PD_RETURN:"待歸還",
        BUT_TAH:"轉移和處理",
        BUT_HAD:"處理和銷毀",
        BUT_MODIFY:"修改",
        BUT_TA:"再次轉移",
        BUT_CS:"改變狀態",
        BUT_ADD_NEXT: "添加下壹個",
        BUT_CLOSE:"關閉",           
        BUT_CANCEL: "取消",
        BUT_CLEAR: "取消",
        BUT_SAVE: "保存",
        BUT_UPDATE:"更新",
        BUT_BIND:"綁定",
        CREATE_NEW_TRANSFER:"創建轉移"
    },
    LABEL:{
        ADD_NEW:"新增",
        DELETED:"已刪除",
        EDIT_ITEM:"編輯項",
        BIND_ITEM:"綁定項",
        REPORT: "報表", 
        DETAILS:"詳情",  
        REQUIRED_FIELDS:"必填字段",  
        APPLY_ADMIN:"申請管理員",         
        CHANGE_PASSWORD:"更改密碼",
        CHANG_LANGUAGE:"更改語言",
        LOGOUT:"退出",
        CHANGE_UI_LANGUAGE:"選擇用戶界面語言", 
        SEARCH:"查詢",
        ADVANCED_SEARCH:"高級查詢",
        CLEAR_CLOSE:"清除並關閉", 
        NO_ACCESS_PERMISSION:"沒有訪問權限",
        NO_RESULTS_FOUND:"沒有發現結果!",
        CHANGE_USER_TYPE:"更改用戶類型",
        STAR_SEARCH_PRO:`開始搜索記錄<br>
                        以確保您想要搜索的記錄確實已經存在於系統中`,
        RESV_STATUS:"預定狀態:",
        DUE_IN:"待入住",
        IN_HOUSE:"在店",  
        DUE_OUT:"待離店",  
        CHECKED_OUT:"已離店",
        RESERVED:"已預定的",
        CHECK_ALL:"全選",
        IN_ONE_MONTH: "壹個月內",
        TYPE:"種類",
        PLACE:"地點",
        PLACE_OF_LOST:"丟失地點",
        FOUND_PLACE:"撿拾地點",
        FOUND_TIME_FROM:"撿拾時間從",
        FOUND_TIME_TO:"撿拾時間到",
        RETURNING_DATE_FROM:"返還日期從",
        RETURNING_DATE_TO:"返還日期到",
        COLOR:"顏色",
        RETURNING_METHOD:"歸還方式",
        LOST_DATE_FROM:"丟失時間從",
        LOST_DATE_TO:"丟失時間到",
        LAST_UPDATE_FROM:"更新時間從",
        LAST_UPDATE_TO:"更新時間到",
        FILTER_BY:"篩選",
        S_S_M_T_D_R:"選擇指定的合並時間或日期範圍",
        LAST_24H:"過去24小時",
        LAST_WEEK:"過去壹周",
        CUSTOM_RANGE:"自定義時間",
        FROM:"從",
        TO:"到",
        ALREADY_SOLVED_OVERDUE_LOST_ITEMS:"已處理的逾期物品",
        UNSOLVED_OVERDUE_LOST_ITEMS:"未處理的逾期物品",
        TOTAL_QUANTITY_OF_HANDOVER_ITEMS:"交接物品",
        TOTAL_QUANTITY_OF_ACCEPTED_ITEMS:"已接收物品",
        FILTER_GRID:"過濾條件",
        ALREADY_HANDOVER:"已發起",
        PENDING:"待完成",
        TYPE_OF_LF: "遺失物種類",
        PLACE_OF_LF: "遺失物拾取地點",
        STORAGE_LOCATION: "存儲地點",
        LOST_PROPERTY_COLOR: "遺失物顏色",
        HANDLE_METHOD: "遺失物處理方式",
        COMMUNICATION_MODE: "通信方式",
        COMMUNICATION_TYPE: "通信類型",
        IDS_TYPE: "IDS 類型",
        SEQUENCE:"序號",
        TYPE_CODE: "類型碼",
        TYPE_DESCRIPTION:"類型描述",
        PLACE_CODE:"地點碼",
        PLACE_DESCRIPTION:"地點描述",
        LOCATION_CODE:"存儲地點碼",
        LOCATION_DESCRIPTION:"存儲地點描述",
        COLOR_HEX:"顏色十六進制",
        COLOR_CODE:"顏色碼",
        COLOR_DESCRIPTION:"顏色描述",
        HANDLE_CODE:"處理碼",
        HANDLE_DESCRIPTION:"處理描述",
        MODE_CODE:"通信方式碼",
        MODE_DESCRIPTION:"通信方式描述",
        MODE:"方式",
        GUEST_OWNER_INFORMATION:"客人/失主信息",
        OWNER_DETAILS:"失主資料",
        LOST_PROPERTY_DETAILS:"失物詳細信息",
        FOUND_PROPERTY_DETAILS:"撿拾物詳細信息",
        ATTACHMENTS:"附件和圖片",
        COMMUNICATION_DETAILS:"通信詳細信息",
        GUEST_IDS:"客人IDs",
        PLEASE_UPLOAD_THE_PICTURE_OF_FOUND_PROPERTY_IN_THE_ATTACHMENT:"請在附件上傳遺失物圖片",
        LAST_NAME:"姓氏",
        FIRST_NAME :"名字",
        DEPARTURE_DATE:"離店日期",
        ARRIVAL_DATE:"抵店日期",
        BRAND:"品牌",
        AMOUNT:"數量",
        UNIT:"單位",
        DATE_OF_LOST:"丟失日期",
        NON_RESIDENT:"非住店客人",
        RESIDENT:"住店客人",
        NO_IMAGES:"沒有圖片",
        PRIMARY: "主鍵",
        DETAIL:"詳情",
        DOCUMENT_TYPE:"證件類別",
        NUMBER:"編號",
        LAST_NUMBER:"最後4位編號",
        EXPIRY_DATE:"到期日",
        ISSUE_DATE:"發行日期",
        COUNTRY_OF_ISSUE:"簽發國",
        PLACE_OF_ISSUE:"簽發地",
        ISSUED_BYE:"簽發於",
        DATE_OF_BIRTH:"出生日期",
        COUNTRY_OF_BIRTH:"出生國",
        PLACE_OF_BIRTH:"出生地",
        NATIONALITY:"國籍",
        VISA_NUMBER:"簽證號碼",
        DESTRUCT:"銷毀",
        METHOD:"銷毀方式",
        OPERATOR_NAME:"操作員",
        ITEM_DISPOSED_SERIAL_NUMBER:"處理批次號",
        HANDLE_DATE:"接收日期和時間",
        MTAH:"修改轉移處理",
        HD:"發起詳情",
        OD:"接收詳情",
        ENTER_OPERATOR_NAME:"輸入操作人姓名",
        STORAGE_DETAILS:"存儲詳情",
        DOF:"撿拾日期",
        EXPIRATION_DATE:"到期日期",
        DEPARTMENT:"部門",
        EMPLOYEE_NAME:"員工姓名",
        REGISTRATION_NUMBER:"登記單號",
        PUBLIC_PLACES_DETAILS:"公共地點詳情",
        ACCEPTER_INFORMATIONS:"領取人信息",
        
        RESERVATION_STATUS:"預訂狀態",
        NON_OWNER:"非所有者",
        YAHBSFRPWP:"妳的申請已提交審核，請耐心等待!",
        POST_DETAIL:"郵寄地址",
        DESTRUCT_DETIAILS:"銷毀詳細信息",
        HANDLE_DATE_FROM:"處理日期從",
        HANDLE_DATE_TO:"處理日期到",
        HANDOVER_DATE_FROM: "移交日期從",
        HANDOVER_DATE_TO:"移交日期到",
        OPERATION_DATE_FROM:"操作日期從",
        OPERATION_DATE_TO:"操作日期到",
        FOUND_DATE_FROM:"撿拾日期從",
        FOUND_DATE_TO:"撿拾日期到",
        TYPE_ICON:"類型圖標",
        TFIRTI_PROM:"此字段必填",
        TFIRTI:"必須上傳壹個類型圖標",
        SELECTED_ITEMS:"選定的項目:",
        UNSELECT_ALL:"取消全選",
        
    },
    PERMISSION_LABEL:{
        NONE:"沒有操作權限",
        SELECT:"查詢權限",
        INSERT:"添加權限",
        UPDATE:"編輯權限",
        DELETE:"刪除權限",
        ALL:"管理員權限"
    },
    TABLE:{
        TH_SN:"遺失物序列號",	
        TH_ITEMS:"物品",	
        TH_STATUS:"狀態",	
        TH_TYPE:"種類",
        TH_COLOR:"顏色",
        TH_PLACE_O_LOST:"丟失地點",
        TH_OWNER:"失主",
        TH_MEMBERSHIP:"會員",
        TH_ROOM:"房間",
        TH_RS:"預定狀態",
        TH_CN:"確認號",
        TH_LOST_TIME:"丟失時間",
        TH_CREATE_TIME:"創建時間",
        TH_UPDATE_TIME:"更新時間",
        TH_FOUND_PLACE:"撿拾地點",
        TH_STORAGE_LOCATION:"存儲地點",
        TH_GUEST:"客人",
        TH_FOUND_TIME:"撿拾時間",
        TH_RETURNING_METHOD:"歸還方式",
        TH_RETURNING_DATE:"歸還時間",
        TH_ACCEPTOR:"領取人",
        TH_CONTACT:"聯系方式",
        TH_HANDLE_TIME:"處理日期",
        TH_EXPIRE_TIME:"過期日期",
        TH_DURATION:"過期日期距今",
        TH_STORAGE_LOCATION_FROM:"存儲地點從",
        TH_STORAGE_LOCATION_TO:"存儲地點到",
        TH_HANDOVER_INITIATOR:"發起人",
        TH_HANDOVER_DATE:"發起時間",
        TH_OPERATOR:"操作人",
        TH_OPERATION_DATE:"操作時間",
        TH_SEQUENCE: "序號",
        TH_NAME: "名稱",
        TH_DESCRIPTION: "描述",
        TH_GENERATE: "生成",
        TH_PREVIEW: "預覽"
        
    },
    SIGNIN: {
        PRIVACY_POLICY:"隱私政策",
        LEGAL_NOTICE:"法律公告",
        CONTACT_SUPPORT:"聯系支持",
        ALL_RIGHTS_RESERVED:"石基集團。版權所有。",
        page_title: "登錄到您的帳戶",
        placeholder:"Fill",
        
        label_tenant:"承租戶",
        label_Selected_tenant:"已選擇承租戶",
        label_remember:"記住此設備上的{tree}",
        label_username:"登錄",
        input_username:"用戶名",
        label_password:"密碼",
        input_password:"密碼",
        button_signin:"登錄",
        text_rest_password:"重置密碼",
        text_btn_clear_tenant:"點擊忘記",
        text_signup:"註冊",
        text_signup_des:"加入 {system_name} 創建您的帳戶",
        button_create_account:"創建帳戶!",
        response_error:"用戶名或密碼不正確。",
        response_403_error:"權限過期."
    },
    SIGNUP: {
        page_top:"加入{system_name}",
        page_title: "創建您的帳戶",
        input_username:"用戶名",
        input_email:"電子郵箱",
        input_password:"密碼",
        input_repassword:"重復密碼",
        button_signup:"創建帳戶",
    },
    RESET_PASSWORD:{
        page_title:"重置密碼",
        text_des:"輸入您的電子郵件地址，我們將向您發送重置密碼的鏈接。",
        input_email:"輸入您的電子郵件地址",
        button_send:"發送密碼重置電子郵件"
    },
    REPORT:{
        LOST:"遺失物品日報表",
        CUSTODY:"保管中物品報表",
        HANDOVER:"轉移保管地點報表",
        RECEIVED:"歸還報表",
        DESTRUCTION:"物品處理報表",
        REPORT_PARAMETERS:"報表參數",
        CREATE_TIME_FROM: "創建時間從",
        CREATE_TIME_TO: "創建時間到",
        ACTIONS:"操作",
        DOWNLOAD:"下載",
    },
    MENU:{
        HOME:"主頁",
        BACK:"返回",
        NEW_RECORD:"新增記錄",
        LUGGAGE:"行李",
        DASHBOARD:"控制面板",

        LUGGAGE_DASHBOARD:"行李控制面板",  
        LUGGAGE_NEW_RECORD:"創建新記錄",
        LUGGAGE_EDIT:"查看/編輯 記錄",
        LUGGAGE_CONFIG:"配置", 
        LUGGAGE_SETTINGS:"設置",
        LUGGAGE_ACTIVITY_LOG:"活動日誌",  

        USERS:"用戶",
        SETTINGS:"設置",
        LUGGAGE_WHITELIST:"白名單設置",

        LOSTFOUND:"失物招領",
        LF_DASHBOARD:"失物招領控制面板", 
        LOSTFOUND_GLGD: "客人遺失物報備",
        LOSTFOUND_CLGR: "創建新的遺失物報備",
        LOSTFOUND_NEW_LOST:"創建新的遺失物報備",
        LOSTFOUND_EDIT_LOST:"編輯遺失物品",
        LOSTFOUND_EDIT_LOST_FOUND:"拾取",
        LOSTFOUND_EDIT_PICKUP:"編輯拾取物品",
        LOSTFOUND_RETURN_DETAILS:"歸還詳情",
        LOSTFOUND_HANDLING_DETAILS:"處理和銷毀詳情",
        LOSTFOUND_RET:"失物歸還",
        LOSTFOUND_RETURN_LOST:"歸還",

        LOSTFOUND_PGD: "撿拾物品登記",
       
        LOSTFOUND_NEW_PICKUP:"創建新的遺失物撿拾",

        LOSTFOUND_CSCD: "撿拾物品存儲和保管",
        LOSTFOUND_CSC:"轉移和處理", 
        LOSTFOUND_RI: "歸還物品",
        LOSTFOUND_HAD: "處理和銷毀",
        LOSTFOUND_UOR: "用戶操作記錄",
        LOSTFOUND_SR: "系統報表",
        LOSTFOUND_PDF_PREVIEW:"Pdf 預覽",
        LOSTFOUND_CONFIG:"配置",
       
   }
}