export const lang= {
        TREE:"Tenant",
        LANGUAGES:"多语言",
        SYSTEM_NAME:"xxxLuggage",
        COMPANY_NAME:"xxxxx",
        COPYRIGHT:" © {year} {company_name}公司, 版权所有。",
        INPUT:{
            placeholder:"Fill",
            placeholder_select:"选择",
            datefomat:"年/月/日"
        },
        STATUS:{        
            LOST:"遗失",
            FOUND:"拾取",
            RETURNED:"已归还",
            PENDING_RETURN:"待归还",            
            PENDING:"待接收",
            ACCEPTED:"已接收",
            FINISHED:"已处理",
            UNFINISHED:"未处理",
            DETAIL:"详情",
            SELF_PICKUP:"自取",
            POST:"邮寄",
            
        },
        BUTTON:{
            BUT_ADD: "添加",
            BUT_ADD_ICON:"添加图标",
            BUT_EDIT:"编辑",
            BUT_FOUND:"拾取",
            BUT_DELETED:"删除",
            BUT_TRANS:"转移",
            BUT_RETURN:"归还",
            BUT_PD_RETURN:"待归还",
            BUT_TAH:"转移和处理",
            BUT_HAD:"处理和销毁",
            BUT_MODIFY:"修改",
            BUT_TA:"再次转移",
            BUT_CS:"改变状态",
            BUT_ADD_NEXT: "添加下一个",
            BUT_CLOSE:"关闭",           
            BUT_CANCEL: "取消",
            BUT_CANCEL_WATERMARK: "取消水印",
            BUT_CLEAR: "取消",
            BUT_SAVE: "保存",
            BUT_UPDATE:"更新",
            BUT_BIND:"绑定",
            CREATE_NEW_TRANSFER:"创建转移"
        },
        LABEL:{
            ADD_NEW:"新增",
            DELETED:"已删除",
            EDIT_ITEM:"编辑项",
            BIND_ITEM:"绑定项",
            REPORT: "报表", 
            DETAILS:"详情",  
            REQUIRED_FIELDS:"必填字段",  
            APPLY_ADMIN:"申请管理员",         
            CHANGE_PASSWORD:"更改密码",
            CHANG_LANGUAGE:"更改语言",
            LOGOUT:"退出",
            CHANGE_UI_LANGUAGE:"选择用户界面语言", 
            SEARCH:"查询",
            ADVANCED_SEARCH:"高级查询",
            CLEAR_CLOSE:"清除并关闭", 
            NO_ACCESS_PERMISSION:"没有访问权限",
            NO_RESULTS_FOUND:"没有发现结果!",
            CHANGE_USER_TYPE:"更改用户类型",
            STAR_SEARCH_PRO:`输入姓名或确认号<br>
                             以确保您想要搜索的记录确实已经存在于系统中`,
            SYNCHRONIZED_UPDATING:`{field_name}已在SEP平台中更新`,
            SYNCHRONIZED_EXPIRED:`{field_name}已过期,`,
            SYNCHRONIZED_PCA:`请与管理员联系`,
            NOT_ALLOWED_GET_RESERVATIONS:`你没有权限查询预定信息`,
            EXPIRED:'已失效',
            RENEWING:'确认同步',
            RESV_STATUS:"预定状态:",
            DUE_IN:"待入住",
            IN_HOUSE:"在店",  
            DUE_OUT:"待离店",  
            CHECKED_OUT:"已离店",
            RESERVED:"已预定的",
            CHECK_ALL:"全选",
            IN_ONE_MONTH: "一个月内",
            USER:"用户",
            TYPE:"种类",
            PLACE:"地点",
            PLACE_OF_LOST:"丢失地点",
            FOUND_PLACE:"捡拾地点",
            FOUND_TIME_FROM:"捡拾时间从",
            FOUND_TIME_TO:"捡拾时间到",
            RETURNING_DATE_FROM:"返还日期从",
            RETURNING_DATE_TO:"返还日期到",
            PENDING_EXPIRE_DATE_FROM:"即将过期日期从",
            PENDING_EXPIRE_DATE_TO:"即将过期日期到",
            COLOR:"颜色",
            RETURNING_METHOD:"归还方式",
            LOST_DATE_FROM:"丢失时间从",
            LOST_DATE_TO:"丢失时间到",
            LAST_UPDATE_FROM:"更新时间从",
            LAST_UPDATE_TO:"更新时间到",
            FILTER_BY:"筛选",
            S_S_M_T_D_R:"选择指定的合并时间或日期范围",
            LAST_24H:"过去24小时",
            LAST_WEEK:"过去一周",
            NEXT_24H:"未来24小时",
            NEXT_WEEK:"未来一周",
            CUSTOM_RANGE:"自定义时间",
            FROM:"从",
            TO:"到",
            ALREADY_SOLVED_OVERDUE_LOST_ITEMS:"已处理的逾期物品",
            UNSOLVED_OVERDUE_LOST_ITEMS:"未处理的逾期物品",
            TOTAL_QUANTITY_OF_HANDOVER_ITEMS:"交接物品",
            TOTAL_QUANTITY_OF_ACCEPTED_ITEMS:"已接收物品",
            TOTAL_QUANTITY_OFPENDING_EXPIRE:"即将过期",
             TOTAL_QUANTITY_OF_PENDING_RETURN:"等待归还",
            FILTER_GRID:"过滤条件",
            ALREADY_HANDOVER:"已发起",
            PENDING:"待完成",
            TYPE_OF_LF: "遗失物种类",
            PLACE_OF_LF: "遗失物拾取地点",
            STORAGE_LOCATION: "存储地点",
            LOST_PROPERTY_COLOR: "遗失物颜色",
            HANDLE_METHOD: "遗失物处理方式",
            COMMUNICATION_MODE: "通信方式",
            COMMUNICATION_TYPE: "通信类型",
            IDS_TYPE: "IDS 类型",
            SEQUENCE:"序号",
            TYPE_CODE: "类型码",
            TYPE_DESCRIPTION:"类型描述",
            PLACE_CODE:"地点码",
            PLACE_DESCRIPTION:"地点描述",
            LOCATION_CODE:"存储地点码",
            LOCATION_DESCRIPTION:"存储地点描述",
            COLOR_HEX:"颜色十六进制",
            COLOR_CODE:"颜色码",
            COLOR_DESCRIPTION:"颜色描述",
            HANDLE_CODE:"处理码",
            HANDLE_DESCRIPTION:"处理描述",
            MODE_CODE:"通信方式码",
            MODE_DESCRIPTION:"通信方式描述",
            MODE:"方式",
            GUEST_OWNER_INFORMATION:"客人/失主信息",
            OWNER_DETAILS:"失主资料",
            LOST_PROPERTY_DETAILS:"失物详细信息",
            FOUND_PROPERTY_DETAILS:"捡拾物详细信息",
            ATTACHMENTS:"附件和图片",
            COMMUNICATION_DETAILS:"通信详细信息",
            GUEST_IDS:"客人IDs",
            PLEASE_UPLOAD_THE_PICTURE_OF_FOUND_PROPERTY_IN_THE_ATTACHMENT:"请在附件上传遗失物图片",
            LAST_NAME:"姓氏",
            FIRST_NAME :"名字",
            DEPARTURE_DATE:"离店日期",
            ARRIVAL_DATE:"抵店日期",
            BRAND:"品牌",
            AMOUNT:"数量",
            UNIT:"单位",
            DATE_OF_LOST:"丢失日期",
            NON_RESIDENT:"非住店客人",
            RESIDENT:"住店客人",
            NO_IMAGES:"没有图片",
            PRIMARY: "主键",
            DETAIL:"详情",
            DOCUMENT_TYPE:"证件类别",
            DOCUMENT_DES:"证件描述",
            NUMBER:"编号",
            LAST_NUMBER:"最后4位编号",
            EXPIRY_DATE:"到期日",
            ISSUE_DATE:"发行日期",
            COUNTRY_OF_ISSUE:"签发国",
            PLACE_OF_ISSUE:"签发地",
            ISSUED_BYE:"签发于",
            DATE_OF_BIRTH:"出生日期",
            COUNTRY_OF_BIRTH:"出生国",
            PLACE_OF_BIRTH:"出生地",
            NATIONALITY:"国籍",
            VISA_NUMBER:"签证号码",
            DESTRUCT:"销毁",
            METHOD:"销毁方式",
            OPERATOR_NAME:"操作员",
            ITEM_DISPOSED_SERIAL_NUMBER:"处理批次号",
            HANDLE_DATE:"接收日期和时间",
            MTAH:"修改转移处理",
            HD:"发起详情",
            OD:"接收详情",
            ENTER_OPERATOR_NAME:"输入操作人姓名",
            STORAGE_DETAILS:"存储详情",
            DOF:"捡拾日期",
            EXPIRATION_DATE:"到期日期",
            DEPARTMENT:"部门",
            VALUED:"宝贵的",
            EMPLOYEE_NAME:"员工姓名",
            REGISTRATION_NUMBER:"登记单号",
            PUBLIC_PLACES_DETAILS:"公共地点详情",
            ACTUAL_COLOR_NAME:"实际颜色名",
            ACCEPTER_INFORMATIONS:"领取人信息",
            // RETURNING_METHOD:"归还方式",
            RESERVATION_STATUS:"预订状态",
            NON_OWNER:"非所有者",
            YAHBSFRPWP:"你的申请已提交审核，请耐心等待!",
            POST_DETAIL:"邮寄地址",
            DESTRUCT_DETIAILS:"销毁详细信息",
            HANDLE_DATE_FROM:"处理日期从",
            HANDLE_DATE_TO:"处理日期到",
            HANDOVER_DATE_FROM: "移交日期从",
            HANDOVER_DATE_TO:"移交日期到",
            OPERATION_DATE_FROM:"操作日期从",
            OPERATION_DATE_TO:"操作日期到",
            FOUND_DATE_FROM:"捡拾日期从",
            FOUND_DATE_TO:"捡拾日期到",
            TYPE_ICON:"类型图标",
            LOGO_ICON:"Logo图标",
            TFIRTI_PROM:"此字段必填",
            TFIRTI:"必须上传一个类型图标",
            PLEASE_EXTENSION:"请确保图片的扩展名为:",
            IMG_EXTENSION:".jpg,.jpeg,.gif,.png",
            SELECTED_ITEMS:"选定的项目:",
            UNSELECT_ALL:"取消全选",
            FOLLOW_UP_ONLY:"跟进",
            INCLUDE_CLOSED:"包含关闭",
            FOLLOW_UP_TIME:"跟进日期",
            GUEST_RESERVATION:"预定客人",
            LUGGAGE_DETAILS:"行李详情",
            INTERNAL_REMARKS:"内部备注",            
            TAG_NUMBER:"标签号",
            MANUAL_OVERRIDE:"手动覆盖",
            TYPE_OF_LUGGAGE:"行李种类",
            DEPOSIT_STATUS_TYPE:"状态种类",
            DEPOSIT_STATUS_CODE:"状态编码",
            DEPOSIT_STATUS_DES:"状态描述",
            DEPOSIT_STATUS:"寄存状态",
            LUGGAGE_COLOR:"行李颜色",
            EVENT_TEXT:"事件文本",
            EVENT_TYPE:"事件类型",
            START_DATE:"开始日期",
            END_DATE:"结束日期",
            
        },
        PERMISSION_LABEL:{
            NONE:"没有操作权限",
            SELECT:"查询权限",
            INSERT:"添加权限",
            UPDATE:"编辑权限",
            DELETE:"删除权限",
            ALL:"管理员权限"
        },
        TABLE:{
            TH_SN:"遗失物序列号",	
            TH_ITEMS:"物品",	
            TH_STATUS:"状态",	
            TH_TYPE:"种类",
            TH_COLOR:"颜色",
            TH_PLACE_O_LOST:"丢失地点",
            TH_OWNER:"失主",
            TH_MEMBERSHIP:"会员",
            TH_ROOM:"房间",
            TH_RS:"预定状态",
            TH_CN:"确认号",
            TH_LOST_TIME:"丢失时间",
            TH_CREATE_TIME:"创建时间",
            TH_UPDATE_TIME:"更新时间",
            TH_FOUND_PLACE:"捡拾地点",
            TH_STORAGE_LOCATION:"存储地点",
            TH_GUEST:"客人",
            TH_FOUND_TIME:"捡拾时间",
            TH_RETURNING_METHOD:"归还方式",
            TH_RETURNING_DATE:"归还时间",
            TH_ACCEPTOR:"领取人",
            TH_CONTACT:"联系方式",
            TH_HANDLE_TIME:"处理日期",
            TH_EXPIRE_TIME:"过期日期",
            TH_DURATION:"过期日期距今",
            TH_CREATE_TIME_FROM:"创建日期从",
            TH_CREATE_TIME_TO:"创建日期到",
            TH_STORAGE_LOCATION_FROM:"存储地点从",
            TH_STORAGE_LOCATION_TO:"存储地点到",
            TH_HANDOVER_INITIATOR:"发起人",
            TH_HANDOVER_DATE:"发起时间",
            TH_OPERATOR:"操作人",
            TH_OPERATION_DATE:"操作时间",
            TH_SEQUENCE: "序号",
            TH_NAME: "名称",
            TH_DESCRIPTION: "描述",
            TH_GENERATE: "生成",
            TH_PREVIEW: "预览",
            TH_EXTRA_CHARGES:"额外费用",
            TH_CURRENCY:"货币",
            
        },
        SIGNIN: {
            PRIVACY_POLICY:"隐私政策",
            LEGAL_NOTICE:"法律公告",
            CONTACT_SUPPORT:"联系支持",
            ALL_RIGHTS_RESERVED:"石基集团。版权所有。",
            page_title: "登录到您的帐户",
            placeholder:"Fill",
            
            label_tenant:"承租户",
            label_Selected_tenant:"已选择承租户",
            label_remember:"记住此设备上的{tree}",
            label_remember_pass:"记住密码",
            label_username:"登录",
            input_username:"用户名",
            label_password:"密码",
            label_keep_logined:"保持登录状态",
            label_keep_logout:"注销完成",
            label_msg_logout:"请关闭浏览器选项卡.",
            label_keep_logined_msg:"这样做可以减少系统要求你登录的次数",
            label_no_show_msg:"不再显示此消息",
            input_password:"密码",
            button_signin:"登录",
            text_rest_password:"重置密码",
            text_btn_clear_tenant:"点击忘记",
            text_signup:"注册",
            text_signup_des:"加入 {system_name} 创建您的帐户",
            button_create_account:"创建帐户!",
            response_error:`<ul>
            <li>登录失败。</li>
            <li>可能的原因：</li>
            <ul>
                    <li>承租户名称错误</li>
                    <li>登录或密码错误</li>
                    <li>由于失败的登录尝试次数，帐户被锁定</li>
                    <li>帐户处于非活动状态</li>
                    <li>帐户被阻止</li>
            </ul>
            <li>如需更多支持，请与管理员联系。</li>
             </ul>`,
             response_noorgs_error:`<ul>
             <li>登录失败。</li>
            <li>可能的原因：</li>
            <ul>
                     <li>你的帐号不在酒店中</li>
                    <li>承租户名称错误</li>
                    <li>登录或密码错误</li>
                    <li>由于失败的登录尝试次数，帐户被锁定</li>
                    <li>帐户处于非活动状态</li>
                    <li>帐户被阻止</li>
                   
            </ul>
            <li>如需更多支持，请与管理员联系。</li>
            </ul>`,
            response_form_tenant_error:"承租户必填",
            response_form_username_error:"用户名不能少于2个字符.",
            response_form_password_error:"密码不能少于6个字符",
           
            response_403_error:"权限过期."
        },
        SIGNUP: {
            page_top:"加入{system_name}",
            page_title: "创建您的帐户",
            input_username:"用户名",
            input_email:"电子邮箱",
            input_password:"密码",
            input_repassword:"重复密码",
            button_signup:"创建帐户",
        },
        RESET_PASSWORD:{
            page_title:"重置密码",
            text_des:"输入您的电子邮件地址，我们将向您发送重置密码的链接。",
            input_email:"输入您的电子邮件地址",
            button_send:"发送密码重置电子邮件"
        },
        REPORT:{
            LOST:"遗失物品日报表",
            CUSTODY:"保管中物品报表",
            HANDOVER:"转移保管地点报表",
            RECEIVED:"归还报表",
            DESTRUCTION:"物品处理报表",
            PENDINGRETURN:"等待归还报表",
            PENDINGEXPIRE:"即将过期报表",
            REPORT_PARAMETERS:"报表参数",
            CREATE_TIME_FROM: "创建时间从",
            CREATE_TIME_TO: "创建时间到",
            ACTIONS:"操作",
            DOWNLOAD:"下载",
        },
        MENU:{
            HOME:"主页",
            BACK:"返回",
            CALCULATOR:"Calculator",
            NEW_RECORD:"新增记录",
            LUGGAGE:"行李",
            DASHBOARD:"控制面板",

            LUGGAGE_DASHBOARD:"行李控制面板",  
            LUGGAGE_NEW_RECORD:"创建新记录",
            LUGGAGE_EDIT:"查看/编辑 记录",
            LUGGAGE_CONFIG:"配置", 
            LUGGAGE_SETTINGS:"设置",
            LUGGAGE_ACTIVITY_LOG:"活动日志",  

            USERS:"用户",
            SETTINGS:"设置",
            LUGGAGE_WHITELIST:"白名单设置",

            LOSTFOUND:"失物招领",
            LF_DASHBOARD:"失物招领控制面板", 
            LOSTFOUND_GLGD: "客人遗失物报备",
            LOSTFOUND_CLGR: "创建新的遗失物报备",
            LOSTFOUND_NEW_LOST:"创建新的遗失物报备",
            LOSTFOUND_EDIT_LOST:"编辑遗失物品",
            LOSTFOUND_EDIT_LOST_FOUND:"拾取",
            LOSTFOUND_EDIT_PICKUP:"编辑拾取物品",
            LOSTFOUND_RETURN_DETAILS:"归还详情",
            LOSTFOUND_HANDLING_DETAILS:"处理和销毁详情",
            LOSTFOUND_RET:"失物归还",
            LOSTFOUND_RETURN_LOST:"归还",

            LOSTFOUND_PGD: "捡拾物品登记",
           
            LOSTFOUND_NEW_PICKUP:"创建新的遗失物捡拾",

            LOSTFOUND_CSCD: "捡拾物品存储和保管",
            LOSTFOUND_CSC:"转移和处理", 
            LOSTFOUND_RI: "归还物品",
            LOSTFOUND_HAD: "处理和销毁",
            LOSTFOUND_UOR: "用户操作记录",
            LOSTFOUND_SR: "系统报表",
            LOSTFOUND_PDF_PREVIEW:"Pdf 预览",
            LOSTFOUND_CONFIG:"配置",

            RMX_DASHBOARD:"客房矩阵控制面板",
            ROOMMATRIX:"客房矩阵",
           
       }
  }