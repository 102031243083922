export const lang=  {
    FILDENAME:"KKKKKK",
    TREE:"Tenant",
    LANGUAGES:"Languages",
    SYSTEM_NAME:"xxxLuggage",
    COMPANY_NAME:"xxxxx",
    COPYRIGHT:" © {year} {company_name}, Inc.",
    INPUT:{
        placeholder:"Fill",
        placeholder_select:"Select",
        datefomat:"YYYY/MM/DD"
    },
    STATUS:{        
        LOST:"lost",
        FOUND:"found",
        RETURNED:"returned",
        PENDING_RETURN:"pending return",
        PENDING:"pending",
        ACCEPTED:"accepted",
        FINISHED:"finished",
        UNFINISHED:"unfinished",
        DETAIL:"detail",
        SELF_PICKUP:"self_pickup",
        POST:"post",
    },
    BUTTON:{
        BUT_ADD: "Add",
        BUT_ADD_ICON:"Add Icon",
        BUT_EDIT:"Edit",
        BUT_FOUND:"Found",
        BUT_DELETED:"Delete",
        BUT_TRANS:"Transfer",
        BUT_RETURN:"Return",
        BUT_PD_RETURN:"Pending Return",
        BUT_TAH:"Transfer and Handover",
        BUT_HAD:"Handle And Destruct",
        BUT_MODIFY:"Modify",
        BUT_TA:"Transfer again",
        BUT_CS:"Change Status",
        BUT_ADD_NEXT: "Add Next",
        BUT_CLOSE:"Close",
        BUT_CANCEL: "Cancel",
        BUT_CANCEL_WATERMARK: "Cancel Watermark",
        BUT_CLEAR: "Clear",
        BUT_SAVE: "Save",
        BUT_UPDATE:"Update",
        BUT_BIND:"Bind",
        CREATE_NEW_TRANSFER:"Create New Transfer"
    },
    LABEL:{
        ADD_NEW:"Add new",
        DELETED:"Deleted",
        EDIT_ITEM:"Edit item",
        BIND_ITEM:"Bind item",    
        REPORT: "Report",  
        DETAILS:"Details",
        REQUIRED_FIELDS:"Required fields",
        APPLY_ADMIN:"Apply for an Administrator",
        CHANGE_PASSWORD:"Change password",
        CHANG_LANGUAGE:"Change language",
        LOGOUT:"Logout",
        CHANGE_UI_LANGUAGE:"Change UI Language",
        SEARCH:"Search",
        ADVANCED_SEARCH:"Advanced Search",
        CLEAR_CLOSE:"Clear & Close",
        NO_ACCESS_PERMISSION:"No permission access ",
        NO_RESULTS_FOUND:"No Results Found !",
        CHANGE_USER_TYPE:"Change User Type",
        STAR_SEARCH_PRO:`Input confirmation number or guest name to <br /> make sure that record
        you want to searching does already exist in the system. `,
        SYNCHRONIZED_UPDATING:`The {field_name} has been updated in SEP Platform,` ,
        SYNCHRONIZED_EXPIRED:` The {field_name} has been expired,`,
        SYNCHRONIZED_PCA:` Please connect administrator`,
        NOT_ALLOWED_GET_RESERVATIONS:`You are not allowed to perform operation GetReservationsDashboardList`,
        EXPIRED:'Expired',
        RENEWING:'Renewing',
        RESV_STATUS:"Resv. Status:",
        DUE_IN:"Due In",
        IN_HOUSE:"In-House",  
        DUE_OUT:"Due Out",  
        CHECKED_OUT:"Checked Out",
        RESERVED:"Reserved",
        CHECK_ALL:"Check All",
        IN_ONE_MONTH: "In One Month",
        USER:"User",
        TYPE:"Type",
        PLACE:"Place",
        PLACE_OF_LOST:"Place Of Lost",
        FOUND_PLACE:"Found Place",
        FOUND_TIME_FROM:"Found Date From",
        FOUND_TIME_TO:"Found Date To",
        RETURNING_DATE_FROM:"Returning Date From",
        RETURNING_DATE_TO:"Returning Date To",
        PENDING_EXPIRE_DATE_FROM:"Pending Expire Date From",
        PENDING_EXPIRE_DATE_TO:"Pending Expire Date To",
        COLOR:"Color",
        RETURNING_METHOD:"Returning Method",
        LOST_DATE_FROM:"Lost Date From",
        LOST_DATE_TO:"Lost Date To",
        LAST_UPDATE_FROM:"Last Update From",
        LAST_UPDATE_TO:"Last Update To",
        FILTER_BY:"Filter by",
        S_S_M_T_D_R:"Select a specified merging time or Date Range",
        LAST_24H:"Last 24h",
        LAST_WEEK:"Last Week",
        NEXT_24H:"Next 24h",
        NEXT_WEEK:"Next Week",
        CUSTOM_RANGE:"Custom Range",
        FROM:"From",
        TO:"To",
        ALREADY_SOLVED_OVERDUE_LOST_ITEMS:"Already Solved Overdue Found Items",
        UNSOLVED_OVERDUE_LOST_ITEMS:"Unsolved Overdue Found Items",
        TOTAL_QUANTITY_OF_HANDOVER_ITEMS:"Total Quantity of Handover Items",
        TOTAL_QUANTITY_OF_ACCEPTED_ITEMS:"Total Quantity of Accepted Items",

        TOTAL_QUANTITY_OFPENDING_EXPIRE:"Pending Expire",
        TOTAL_QUANTITY_OF_PENDING_RETURN:"Pending Return",

        FILTER_GRID:"Filter grid",
        ALREADY_HANDOVER:"Already Handover",
        PENDING:"Pending",
        TYPE_OF_LF: "Type of L&f",
        PLACE_OF_LF: "Place Of L&f",
        STORAGE_LOCATION: "Storage Location",
        LOST_PROPERTY_COLOR: "Lost Property Color",
        HANDLE_METHOD: "Handle Method",
        COMMUNICATION_MODE: "Communication Mode",
        COMMUNICATION_TYPE: "Communication Type",
        IDS_TYPE: "Ids Type",
        SEQUENCE:"Sequence",
        TYPE_CODE: "Type Code",
        TYPE_DESCRIPTION:"Type Description",
        PLACE_CODE:"Place Code",
        PLACE_DESCRIPTION:"Place Description",
        LOCATION_CODE:"Location Code",
        LOCATION_DESCRIPTION:"Location Description",
        COLOR_HEX:"Color hex",
        COLOR_CODE:"Color Code",
        COLOR_DESCRIPTION:"Color Description",
        HANDLE_CODE:"Handle Code",
        HANDLE_DESCRIPTION:"Handle Description",
        MODE_CODE:"Mode Code",
        MODE_DESCRIPTION:"Mode Description",
        MODE:"Mode",
        GUEST_OWNER_INFORMATION:"Guest/Owner Information",
        OWNER_DETAILS:"Owner Details",
        LOST_PROPERTY_DETAILS:"Lost Property Details",
        FOUND_PROPERTY_DETAILS:"Article Found Details",
        ATTACHMENTS:"Attachments & Photos",
        COMMUNICATION_DETAILS:"Communication Details",
        GUEST_IDS:"Guest IDs",
        PLEASE_UPLOAD_THE_PICTURE_OF_FOUND_PROPERTY_IN_THE_ATTACHMENT:"Please upload the picture of found property in the attachment",
        LAST_NAME:"Last Name",
        FIRST_NAME :"First Name",
        DEPARTURE_DATE:"Departure Date",
        ARRIVAL_DATE:"Arrival Date",
        BRAND:"Brand",
        AMOUNT:"Amount",
        UNIT:"Unit",
        DATE_OF_LOST:"Date of lost",
        NON_RESIDENT:"Non-Resident",
        RESIDENT:"Resident",
        NO_IMAGES:"No images",
        PRIMARY: "Primary",
        DETAIL:"Detail",
        DOCUMENT_TYPE:"Document Type",
        DOCUMENT_DES:"Document Description",
        NUMBER:"Number",
        LAST_NUMBER:"Last 4 Number",
        EXPIRY_DATE:"Expiry Date",
        ISSUE_DATE:"Issue Date",
        COUNTRY_OF_ISSUE:"Country of Issue",
        PLACE_OF_ISSUE:"Place of Issue",
        ISSUED_BYE:"Issued bye",
        DATE_OF_BIRTH:"Date of Birth",
        COUNTRY_OF_BIRTH:"Country of Birth",
        PLACE_OF_BIRTH:"Place of Birth",
        NATIONALITY:"Nationality",
        VISA_NUMBER:"Visa Number",
        DESTRUCT:"Destruct",
        METHOD:"Method",
        OPERATOR_NAME:"Operator Name",
        ITEM_DISPOSED_SERIAL_NUMBER:"Item Disposed Serial Number",
        HANDLE_DATE:"Handle Date",
        MTAH:"Modify Transfer and Handover",
        HD:"Handover Details",
        OD:"Operation Details",
        ENTER_OPERATOR_NAME:"Enter Operator Name",
        STORAGE_DETAILS:"Storage Details",
        DOF:"Date of found",
        EXPIRATION_DATE:"Expiration Date",
        DEPARTMENT:"Department",
        VALUED:"Valued",
        EMPLOYEE_NAME:"Employee Name",
        REGISTRATION_NUMBER:"Registration Number",
        PUBLIC_PLACES_DETAILS:"Public Places Details",
        ACTUAL_COLOR_NAME:"Actual Color Name",
        ACCEPTER_INFORMATIONS:"Accepter Information",
        // RETURNING_METHOD:" Returning Method",
        RESERVATION_STATUS:"Reservation Status",
        NON_OWNER:"Non-Owner",
        YAHBSFRPWP:"Your application has been submitted for review. Please wait patiently!",
        POST_DETAIL:"Post Detail",
        DESTRUCT_DETIAILS:"Destruct  Details",
        HANDLE_DATE_FROM:"Handle Date From",
        HANDLE_DATE_TO:"Handle Date To",
        HANDOVER_DATE_FROM: "Handover Date From",
        HANDOVER_DATE_TO:"Handover Date To",
        OPERATION_DATE_FROM:"Operation Date From",
        OPERATION_DATE_TO:"Operation Date To",
        FOUND_DATE_FROM:"Found Date From",
        FOUND_DATE_TO:"Found Date To",
        TYPE_ICON:"Type Icon",
        LOGO_ICON:"Logo Icon",
        TFIRTI_PROM:"This field is required",
        TFIRTI:"This field is required, Please upload the type icon",
        PLEASE_EXTENSION:"Please make sure the extension of the picture is:",
        IMG_EXTENSION:".jpg,.jpeg,.gif,.png",
        SELECTED_ITEMS:"Selected items:",
        UNSELECT_ALL:"Unselect All",
        FOLLOW_UP_ONLY:"Follow Up Only",
        INCLUDE_CLOSED:"Include Closed",
        FOLLOW_UP_TIME:"Follow Up Time",
        GUEST_RESERVATION:"Guest Reservation",
        LUGGAGE_DETAILS:"Luggage Details",
        INTERNAL_REMARKS:"Internal Remarks",      
        TAG_NUMBER:"Tag Number",
        MANUAL_OVERRIDE:"Manual Override",
        TYPE_OF_LUGGAGE:"Type of Luggage",
        DEPOSIT_STATUS_TYPE:"Status Type",
        DEPOSIT_STATUS_CODE:"Status Code",
        DEPOSIT_STATUS_DES:"Status Description",
        DEPOSIT_STATUS:"Deposit Status",
        LUGGAGE_COLOR:"Luggage Color",
        EVENT_TEXT:"Event Text",
        EVENT_TYPE:"Event Type",
        START_DATE:"Start Date",
        END_DATE:"End Date",
    },
    PERMISSION_LABEL:{
        NONE:"No operation permission",
        SELECT:"Query permission",
        INSERT:"Insert permission",
        UPDATE:"Edit permission",
        DELETE:"Delete permission",
        ALL:"Administrator rights"
    },
    TABLE:{
        TH_SN:"Serial Number",	
        TH_ITEMS:"Items",	
        TH_STATUS:"Status",	
        TH_TYPE:"Type",
        TH_COLOR:"Color",
        TH_PLACE_O_LOST:"Place Of Lost",
        TH_OWNER:"Owner",
        TH_MEMBERSHIP:"Membership",
        TH_ROOM:"Room",
        TH_RS:"Res.Status",
        TH_CN:"Confirmation Number",
        TH_LOST_TIME:"Lost Date",
        TH_CREATE_TIME:"Create Date",
        TH_UPDATE_TIME:"Update Date",
        TH_FOUND_PLACE:"Found Place",
        TH_STORAGE_LOCATION:"Storage Location",
        TH_GUEST:"Guest",
        TH_FOUND_TIME:"Found Date",
        TH_RETURNING_METHOD:"Returning Method",
        TH_RETURNING_DATE:"Returning Date",
        TH_ACCEPTOR:"Acceptor",
        TH_CONTACT:"Contact",
        TH_HANDLE_TIME:"Handle Date",
        TH_EXPIRE_TIME:"Expire Date",
        TH_DURATION:"Duration",
        TH_CREATE_TIME_FROM:"Create Date From",
        TH_CREATE_TIME_TO:"Create Date To",
        TH_STORAGE_LOCATION_FROM:"Storage Location From",
        TH_STORAGE_LOCATION_TO:"Storage Location To",
        TH_HANDOVER_INITIATOR:"Handover Initiator",
        TH_HANDOVER_DATE:"Handover Date",
        TH_OPERATOR:"Operator",
        TH_OPERATION_DATE:"Operation Date",
        TH_SEQUENCE: "Sequence",
        TH_NAME: "Name",
        TH_DESCRIPTION: "Description",
        TH_GENERATE: "Generate",
        TH_PREVIEW: "Preview",
        TH_EXTRA_CHARGES:"Extra Charges",
        TH_CURRENCY:"Currency",
        
        
    },
    SIGNIN: {
        PRIVACY_POLICY:"Privacy Policy",
        LEGAL_NOTICE:"Legal Notice",
        CONTACT_SUPPORT:"Contact Support",
        ALL_RIGHTS_RESERVED:"Shiji Group. All rights reserved.",
        page_title: "Log In to Your Account",
        placeholder:"Fill",
        
        label_tenant:"Tenant",
        label_Selected_tenant:"Selected Tenant",
        label_remember:"Remember {tree} on this Device",
        label_remember_pass:"Remember Password",
        label_username:"Login",
        input_username:"Username",
        label_password:"Password",
        label_keep_logined:"Stay logged in",
        label_keep_logout:"Log Out Completed",
        label_msg_logout:"Please close the browser tab.",
        label_keep_logined_msg:"This reduces the number of times the system requires you to log in",
        label_no_show_msg:"Don't show this message again",
        input_password:"Password",
        button_signin:"Login",
        text_btn_clear_tenant:"Click to Forget",
        text_rest_password:"Reset Password",
        text_signup:"Sign up",
        text_signup_des:"Join {system_name} Create your account",
        button_create_account:"Create an account!",
        response_error:`<ul>
        <li>Login failed.</li>
        <li>Possible reasons:</li>
        <ul>
                <li>Tenant name is wrong</li>
                <li>Login or password is wrong</li>
                <li>Account is locked due to number of failed login attempts</li>
                <li>Account is inactive</li>
                <li>Account is blocked</li>
        </ul>
        <li>For more support please contact your administrator.</li>
         </ul>`,
         response_noorgs_error:`<ul>
         <li>Login failed.</li>
         <li>Possible reasons:</li>
       
         <ul>
                 <li>Account is not in any hotel</li>
                 <li>Tenant name is wrong</li>
                 <li>Login or password is wrong</li>
                 <li>Account is locked due to number of failed login attempts</li>
                 <li>Account is inactive</li>
                 <li>Account is blocked</li>
                 
         </ul>
        
         <li>For more support please contact your administrator.</li>
          </ul>`,
        response_form_tenant_error:"The tenant is required",
        response_form_username_error:"The user name cannot be less than 2 characters.",
        response_form_password_error:"The password cannot be less than 6 characters.",
        response_403_error:"Permission expired."
    },
    SIGNUP: {
        page_top:"Join {system_name}",
        page_title: "Create your account",
        input_username:"Username",
        input_email:"Email",
        input_password:"Password",
        input_repassword:"Repeat password",
        button_signup:"Create Account",
    },
    RESET_PASSWORD:{
         page_title:"Reset your password",
         text_des:"Enter your email address and we will send you a link to reset your password.",
         input_email:"Enter your email address",
         button_send:"Send password reset email"
    },
    REPORT:{
        LOST:"Lost Items Daily Report",
        CUSTODY:"Custody Items Daily Report",
        HANDOVER:"Storage Handover Report",
        RECEIVED:"Returning Report",
        DESTRUCTION:"Items Destruction Report",
        PENDINGRETURN:"Pending Return Report",
        PENDINGEXPIRE:"Pending Expire Report",
        REPORT_PARAMETERS:"Report Parameters",
        CREATE_TIME_FROM: "Create Date From",
        CREATE_TIME_TO: "Create Date To",
        ACTIONS:"ACTIONS",
        DOWNLOAD:"Download",
    },
    MENU:{
        HOME:"Home",
        BACK:"Back",
        CALCULATOR:"Calculator",
        NEW_RECORD:"New Record",
        LUGGAGE:"Luggage",
        DASHBOARD:"Dashboard",
        LUGGAGE_DASHBOARD:"Luggage Dashboard",
        LUGGAGE_NEW_RECORD:"Create New Record",
        LUGGAGE_EDIT:"View/Edit Record",
        LUGGAGE_CONFIG:"Configuration",
        LUGGAGE_SETTINGS:"Settings",
        LUGGAGE_ACTIVITY_LOG:"Activity Log",
        
      

        USERS:"Users",
        SETTINGS:"Settings",
        LUGGAGE_WHITELIST:"Whitelist settings",

        LOSTFOUND:"Lost & Found",   
        LF_DASHBOARD:"Lost & Found Dashboard",   
        LOSTFOUND_GLGD: "Lost Property Management",
        LOSTFOUND_CLGR: "Create  Lost Property",
        LOSTFOUND_NEW_LOST:"Create New Lost Property",
        LOSTFOUND_EDIT_LOST:"Edit Lost Property",
        LOSTFOUND_EDIT_LOST_FOUND:"Found",
        LOSTFOUND_RETURN_LOST:"Return Lost Property",
        LOSTFOUND_RETURN_DETAILS:"Return Details",
        LOSTFOUND_HANDLING_DETAILS:"Destruction Details",

        LOSTFOUND_PGD: "Article Found Management",
        LOSTFOUND_CPGR: "create Article Found",
        LOSTFOUND_NEW_PICKUP:"Create New Article Found",
        LOSTFOUND_EDIT_PICKUP:"Edit Article Found",

        LOSTFOUND_CSCD: "Storage And Custody Management",
        LOSTFOUND_CSC:"Transfer and Handover", 
        
        LOSTFOUND_HAD:'Handling and Destruction',
        LOSTFOUND_RET:"Returning Management",
        LOSTFOUND_EDIT:"View/Edit Record",

        LOSTFOUND_PSS: "Article Found storage and safekeeping",
        LOSTFOUND_RI: "Return items",
        LOSTFOUND_DAD: "Disposal and destruction",
        LOSTFOUND_UOR: "User operation record",
        LOSTFOUND_SR: "System Report",
        LOSTFOUND_PDF_PREVIEW:"Pdf Preview",
        LOSTFOUND_CONFIG:"Configuration",
        
        RMX_DASHBOARD:"Room Matrix Dashboard",
        ROOMMATRIX:"Room Matrix",
    }
  }