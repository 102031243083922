import { render, staticRenderFns } from "./transferLogOut.vue?vue&type=template&id=38fc430a&scoped=true&"
import script from "./transferLogOut.vue?vue&type=script&lang=js&"
export * from "./transferLogOut.vue?vue&type=script&lang=js&"
import style0 from "./transferLogOut.vue?vue&type=style&index=0&id=38fc430a&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "38fc430a",
  null
  
)

export default component.exports